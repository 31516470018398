<template>
  <HomePanel> </HomePanel>
</template>

<script>
export default {
  name: "HomeSpecial",
  components: {},
  setup() {
    return {};
  }
};
</script>

<style scoped lang="less"></style>
